import { useState } from "react"

import { ListItemText } from "@material-ui/core"
import { useQueryClient } from "@tanstack/react-query"

import { usePatchResponseService } from "src/data/homes/queries/responseServiceQueries"
import {
  IResponseServicePatch,
  ResponseServiceSubscriptionStatus,
} from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MoreButton } from "src/ui/GridTable/MoreButton"

export function EnabledHomesListMoreButton({
  homeId,
  provider,
  autoDispatchEnabled,
  subscriptionStatus,
}: {
  homeId: string
  provider: IResponseServicePatch["provider"]
  autoDispatchEnabled: boolean
  subscriptionStatus: ResponseServiceSubscriptionStatus | undefined
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const { navigate } = useRouter()
  const queryClient = useQueryClient()

  const patchResponseService = usePatchResponseService()
  const subscriptionIsActive = subscriptionStatus === "active"
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [reactivationDialogOpen, setReactivationDialogOpen] = useState(false)

  function cancelResponseService() {
    return handleResponseServicePlanStatus({
      active: false,
      subscription_status: "non_renewing",
    })
  }

  function reactivateResponseService() {
    if (!provider) {
      throw Error("Provider is necessary for re-activating response service")
    }
    handleResponseServicePlanStatus({
      active: true,
      subscription_status: "active",
      provider,
    })
  }

  function handleResponseServicePlanStatus(body: IResponseServicePatch) {
    return patchResponseService.mutate(
      { homeId, orgId: org.id, body },
      {
        onSuccess: async (_, vars) => {
          await queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
          setCancelDialogOpen(false)
          setReactivationDialogOpen(false)
        },
      }
    )
  }

  const moreButtonContents = [
    {
      key: "edit-home-address",
      onClick: () => {
        navigate(Routes.EditHomeAddress.location(homeId))
      },
      contents: <ListItemText primary={t(langKeys.edit_home_information)} />,
    },

    {
      key: "cancel-plan",
      onClick: () => setCancelDialogOpen(true),
      contents: <ListItemText primary={t(langKeys.cancel_plan)} />,
      hidden: !subscriptionIsActive,
    },

    {
      key: "reactivate-plan",
      onClick: () => setReactivationDialogOpen(true),
      contents: <ListItemText primary={t(langKeys.reactivate_plan)} />,
      hidden: subscriptionIsActive,
    },

    {
      key: "toggle-autodispatch",
      onClick: () => {
        patchResponseService.mutate({
          homeId: homeId,
          orgId: org.id,
          body: {
            automatic_dispatch_disabled: autoDispatchEnabled,
          },
        })
      },
      contents: (
        <ListItemText
          primary={
            !autoDispatchEnabled
              ? t(langKeys.guard_assist_turn_on_automatic_callout)
              : t(langKeys.guard_assist_turn_off_automatic_callout)
          }
        />
      ),
    },
  ]

  return (
    <div>
      <MoreButton menuItems={moreButtonContents} />

      <ConfirmDialog
        key="Cancel Guard Assist"
        title={t(langKeys.confirm_cancel)}
        description={t(langKeys.response_service_subscription_cancel)}
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        onConfirm={cancelResponseService}
        loading={patchResponseService.isLoading}
        error={
          patchResponseService.isError &&
          t(langKeys.failed_something_went_wrong)
        }
      />

      <ConfirmDialog
        key="Reactivate Guard Assist"
        title={t(langKeys.confirm_reactivation)}
        description={t(langKeys.response_service_subscription_renew)}
        open={reactivationDialogOpen}
        onClose={() => setReactivationDialogOpen(false)}
        onConfirm={reactivateResponseService}
        loading={patchResponseService.isLoading}
        error={
          patchResponseService.isError &&
          t(langKeys.failed_something_went_wrong)
        }
      />
    </div>
  )
}
