import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ResponseServiceEmptyState } from "src/components/ResponseService/ResponseServiceEmptyState"
import { ResponseServiceHomesList } from "src/components/ResponseService/ResponseServiceHomesList"
import { ResponseServiceLoading } from "src/components/ResponseService/ResponseServiceLoading"
import { useFetchResponseServiceHomes } from "src/data/homes/queries/responseServiceQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"

export function ResponseService() {
  const { limit, offset } = useUrlPager()
  const { t, langKeys } = useTranslate()

  const fetchResponseServiceHomes = useFetchResponseServiceHomes({
    filters: { limit, offset },
  })

  const activeHomesList = fetchResponseServiceHomes.data?.homes || []

  const { isLoading, isError } = fetchResponseServiceHomes

  if (isError) {
    return (
      <MBanner type="error" fullWidth>
        {t(langKeys.failed_contact_support)}
      </MBanner>
    )
  }

  if (isLoading) {
    return <ResponseServiceLoading />
  }

  // User has response service enabled on one or more homes
  if (activeHomesList.length > 0) {
    return (
      <ResponseServiceHomesList
        homes={activeHomesList}
        homesCount={fetchResponseServiceHomes.data?.paging.total_count || 0}
      />
    )
  }

  return <ResponseServiceEmptyState />
}
